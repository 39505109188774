<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class='header-container fixed-top'>
            <header class='header navbar navbar-expand-sm'>
                <ul class='navbar-item theme-brand flex-row text-center me-4'>
                    <li class='nav-item theme-logo'>
                        <router-link to='/'>
                            <img src='@/assets/images/deppes/logo-deppes-white.png' class='navbar-logo' alt='Deppes' />
                        </router-link>
                    </li>
                </ul>
                <div class='d-none horizontal-menu'>
                    <a href='javascript:void(0);' class='sidebarCollapse' data-placement='bottom'
                       @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            class='feather feather-menu'
                        >
                            <line x1='3' y1='12' x2='21' y2='12'></line>
                            <line x1='3' y1='6' x2='21' y2='6'></line>
                            <line x1='3' y1='18' x2='21' y2='18'></line>
                        </svg>
                    </a>
                </div>
<!--                <ul class='navbar-item flex-row ms-md-0 ms-auto'>-->
<!--                    <li class='nav-item align-self-center search-animated'-->
<!--                        :class="{ 'show-search': $store.state.is_show_search }">-->
<!--                        <svg-->
<!--                            @click="$store.commit('toggleSearch', !$store.state.is_show_search)"-->
<!--                            xmlns='http://www.w3.org/2000/svg'-->
<!--                            width='24'-->
<!--                            height='24'-->
<!--                            viewBox='0 0 24 24'-->
<!--                            fill='none'-->
<!--                            stroke='currentColor'-->
<!--                            stroke-width='2'-->
<!--                            stroke-linecap='round'-->
<!--                            stroke-linejoin='round'-->
<!--                            class='feather feather-search toggle-search'-->
<!--                        >-->
<!--                            <circle cx='11' cy='11' r='8'></circle>-->
<!--                            <line x1='21' y1='21' x2='16.65' y2='16.65'></line>-->
<!--                        </svg>-->
<!--                        <form class='form-inline search-full form-inline search'-->
<!--                              :class="{ 'input-focused': $store.state.is_show_search }">-->
<!--                            <div class='search-bar'>-->
<!--                                <input type='text' class='form-control search-form-control ms-lg-auto'-->
<!--                                       placeholder='Search...' />-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </li>-->
<!--                </ul>-->

                <div class='navbar-item flex-row ms-md-auto'>
<!--                    <div class='dark-mode d-flex align-items-center'>-->
<!--                        <a v-if="$store.state.dark_mode == 'light'" href='javascript:;'-->
<!--                           class='d-flex align-items-center' @click="toggleMode('dark')">-->
<!--                            <svg-->
<!--                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                width='20'-->
<!--                                height='20'-->
<!--                                viewBox='0 0 24 24'-->
<!--                                fill='none'-->
<!--                                stroke='currentColor'-->
<!--                                stroke-width='2'-->
<!--                                stroke-linecap='round'-->
<!--                                stroke-linejoin='round'-->
<!--                                class='feather feather-sun'-->
<!--                            >-->
<!--                                <circle cx='12' cy='12' r='5'></circle>-->
<!--                                <line x1='12' y1='1' x2='12' y2='3'></line>-->
<!--                                <line x1='12' y1='21' x2='12' y2='23'></line>-->
<!--                                <line x1='4.22' y1='4.22' x2='5.64' y2='5.64'></line>-->
<!--                                <line x1='18.36' y1='18.36' x2='19.78' y2='19.78'></line>-->
<!--                                <line x1='1' y1='12' x2='3' y2='12'></line>-->
<!--                                <line x1='21' y1='12' x2='23' y2='12'></line>-->
<!--                                <line x1='4.22' y1='19.78' x2='5.64' y2='18.36'></line>-->
<!--                                <line x1='18.36' y1='5.64' x2='19.78' y2='4.22'></line>-->
<!--                            </svg>-->
<!--                            <span class='ms-2'>Light</span>-->
<!--                        </a>-->
<!--                        <a v-if="$store.state.dark_mode == 'dark'" href='javascript:;' class='d-flex align-items-center'-->
<!--                           @click="toggleMode('system')">-->
<!--                            <svg-->
<!--                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                width='20'-->
<!--                                height='20'-->
<!--                                viewBox='0 0 24 24'-->
<!--                                fill='none'-->
<!--                                stroke='currentColor'-->
<!--                                stroke-width='2'-->
<!--                                stroke-linecap='round'-->
<!--                                stroke-linejoin='round'-->
<!--                                class='feather feather-moon'-->
<!--                            >-->
<!--                                <path d='M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z'></path>-->
<!--                            </svg>-->
<!--                            <span class='ms-2'>Dark</span>-->
<!--                        </a>-->
<!--                        <a v-if="$store.state.dark_mode == 'system'" href='javascript:;'-->
<!--                           class='d-flex align-items-center' @click="toggleMode('light')">-->
<!--                            <svg-->
<!--                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                width='20'-->
<!--                                height='20'-->
<!--                                viewBox='0 0 24 24'-->
<!--                                fill='none'-->
<!--                                stroke='currentColor'-->
<!--                                stroke-width='2'-->
<!--                                stroke-linecap='round'-->
<!--                                stroke-linejoin='round'-->
<!--                                class='feather feather-airplay'-->
<!--                            >-->
<!--                                <path-->
<!--                                    d='M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1'></path>-->
<!--                                <polygon points='12 15 17 21 7 21 12 15'></polygon>-->
<!--                            </svg>-->
<!--                            <span class='ms-2'>System</span>-->
<!--                        </a>-->
<!--                    </div>-->

<!--                    <div class='dropdown nav-item notification-dropdown btn-group'>-->
<!--                        <a href='javascript:;' id='ddlnotify' data-bs-toggle='dropdown' aria-expanded='false'-->
<!--                           class='btn dropdown-toggle btn-icon-only nav-link'>-->
<!--                            <svg-->
<!--                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                width='24'-->
<!--                                height='24'-->
<!--                                viewBox='0 0 24 24'-->
<!--                                fill='none'-->
<!--                                stroke='currentColor'-->
<!--                                stroke-width='2'-->
<!--                                stroke-linecap='round'-->
<!--                                stroke-linejoin='round'-->
<!--                                class='feather feather-bell'-->
<!--                            >-->
<!--                                <path d='M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9'></path>-->
<!--                                <path d='M13.73 21a2 2 0 0 1-3.46 0'></path>-->
<!--                            </svg>-->
<!--                            <span class='badge badge-success'></span>-->
<!--                        </a>-->
<!--                        <ul class='dropdown-menu dropdown-menu-right' aria-labelledby='ddlnotify'>-->
<!--                            <li role='presentation'>-->
<!--                                <a href='javascript:;' class='dropdown-item'>-->
<!--                                    <div class='media server-log'>-->
<!--                                        <div class='media-aside align-self-start'>-->
<!--                                            <svg-->
<!--                                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                                width='24'-->
<!--                                                height='24'-->
<!--                                                viewBox='0 0 24 24'-->
<!--                                                fill='none'-->
<!--                                                stroke='currentColor'-->
<!--                                                stroke-width='2'-->
<!--                                                stroke-linecap='round'-->
<!--                                                stroke-linejoin='round'-->
<!--                                                class='feather feather-server'-->
<!--                                            >-->
<!--                                                <rect x='2' y='2' width='20' height='8' rx='2' ry='2'></rect>-->
<!--                                                <rect x='2' y='14' width='20' height='8' rx='2' ry='2'></rect>-->
<!--                                                <line x1='6' y1='6' x2='6' y2='6'></line>-->
<!--                                                <line x1='6' y1='18' x2='6' y2='18'></line>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                        <div class='media-body'>-->
<!--                                            <div class='data-info'>-->
<!--                                                <h6 class=''>Server Rebooted</h6>-->
<!--                                                <p class=''>45 min ago</p>-->
<!--                                            </div>-->

<!--                                            <div class='icon-status'>-->
<!--                                                <svg-->
<!--                                                    xmlns='http://www.w3.org/2000/svg'-->
<!--                                                    width='24'-->
<!--                                                    height='24'-->
<!--                                                    viewBox='0 0 24 24'-->
<!--                                                    fill='none'-->
<!--                                                    stroke='currentColor'-->
<!--                                                    stroke-width='2'-->
<!--                                                    stroke-linecap='round'-->
<!--                                                    stroke-linejoin='round'-->
<!--                                                    class='feather feather-x'-->
<!--                                                >-->
<!--                                                    <line x1='18' y1='6' x2='6' y2='18'></line>-->
<!--                                                    <line x1='6' y1='6' x2='18' y2='18'></line>-->
<!--                                                </svg>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li role='presentation'>-->
<!--                                <hr role='separator' aria-orientation='horizontal' size='0' class='dropdown-divider' />-->
<!--                            </li>-->
<!--                            <li role='presentation'>-->
<!--                                <a href='javascript:;' class='dropdown-item'>-->
<!--                                    <div class='media'>-->
<!--                                        <div class='media-aside align-self-start'>-->
<!--                                            <svg-->
<!--                                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                                width='24'-->
<!--                                                height='24'-->
<!--                                                viewBox='0 0 24 24'-->
<!--                                                fill='none'-->
<!--                                                stroke='currentColor'-->
<!--                                                stroke-width='2'-->
<!--                                                stroke-linecap='round'-->
<!--                                                stroke-linejoin='round'-->
<!--                                                class='feather feather-heart'-->
<!--                                            >-->
<!--                                                <path-->
<!--                                                    d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'-->
<!--                                                ></path>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                        <div class='media-body'>-->
<!--                                            <div class='data-info'>-->
<!--                                                <h6>Licence Expiring Soon</h6>-->
<!--                                                <p>8 hrs ago</p>-->
<!--                                            </div>-->
<!--                                            <div class='icon-status'>-->
<!--                                                <svg-->
<!--                                                    xmlns='http://www.w3.org/2000/svg'-->
<!--                                                    width='24'-->
<!--                                                    height='24'-->
<!--                                                    viewBox='0 0 24 24'-->
<!--                                                    fill='none'-->
<!--                                                    stroke='currentColor'-->
<!--                                                    stroke-width='2'-->
<!--                                                    stroke-linecap='round'-->
<!--                                                    stroke-linejoin='round'-->
<!--                                                    class='feather feather-x'-->
<!--                                                >-->
<!--                                                    <line x1='18' y1='6' x2='6' y2='18'></line>-->
<!--                                                    <line x1='6' y1='6' x2='18' y2='18'></line>-->
<!--                                                </svg>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li role='presentation'>-->
<!--                                <hr role='separator' aria-orientation='horizontal' size='0' class='dropdown-divider' />-->
<!--                            </li>-->
<!--                            <li role='presentation'>-->
<!--                                <a href='javascript:;' class='dropdown-item'>-->
<!--                                    <div class='media file-upload'>-->
<!--                                        <div class='media-aside align-self-start'>-->
<!--                                            <svg-->
<!--                                                xmlns='http://www.w3.org/2000/svg'-->
<!--                                                width='24'-->
<!--                                                height='24'-->
<!--                                                viewBox='0 0 24 24'-->
<!--                                                fill='none'-->
<!--                                                stroke='currentColor'-->
<!--                                                stroke-width='2'-->
<!--                                                stroke-linecap='round'-->
<!--                                                stroke-linejoin='round'-->
<!--                                                class='feather feather-file-text'-->
<!--                                            >-->
<!--                                                <path-->
<!--                                                    d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z'></path>-->
<!--                                                <polyline points='14 2 14 8 20 8'></polyline>-->
<!--                                                <line x1='16' y1='13' x2='8' y2='13'></line>-->
<!--                                                <line x1='16' y1='17' x2='8' y2='17'></line>-->
<!--                                                <polyline points='10 9 9 9 8 9'></polyline>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                        <div class='media-body'>-->
<!--                                            <div class='data-info'>-->
<!--                                                <h6>Kelly Portfolio.pdf</h6>-->
<!--                                                <p>670 kb</p>-->
<!--                                            </div>-->
<!--                                            <div class='icon-status'>-->
<!--                                                <svg-->
<!--                                                    xmlns='http://www.w3.org/2000/svg'-->
<!--                                                    width='24'-->
<!--                                                    height='24'-->
<!--                                                    viewBox='0 0 24 24'-->
<!--                                                    fill='none'-->
<!--                                                    stroke='currentColor'-->
<!--                                                    stroke-width='2'-->
<!--                                                    stroke-linecap='round'-->
<!--                                                    stroke-linejoin='round'-->
<!--                                                    class='feather feather-check'-->
<!--                                                >-->
<!--                                                    <polyline points='20 6 9 17 4 12'></polyline>-->
<!--                                                </svg>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div-->
<!--                                    >-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->

                    <div class='dropdown nav-item user-profile-dropdown btn-group'>
                        <a href='javascript:;' id='ddluser' data-bs-toggle='dropdown' aria-expanded='false'
                           class='btn dropdown-toggle btn-icon-only user nav-link'>
                            <img :src='`https://ui-avatars.com/api/?name=${$store.state.user.user?.name}&color=0e1726&background=fff&bold=true`'
                                 alt='avatar' class='rounded-circle' />
                        </a>
                        <ul class='dropdown-menu dropdown-menu-right' aria-labelledby='ddluser'>
<!--                            <li role='presentation'>-->
<!--                                <router-link to='/' class='dropdown-item'>-->
<!--                                    <svg-->
<!--                                        xmlns='http://www.w3.org/2000/svg'-->
<!--                                        width='24'-->
<!--                                        height='24'-->
<!--                                        viewBox='0 0 24 24'-->
<!--                                        fill='none'-->
<!--                                        stroke='currentColor'-->
<!--                                        stroke-width='2'-->
<!--                                        stroke-linecap='round'-->
<!--                                        stroke-linejoin='round'-->
<!--                                        class='feather feather-user'-->
<!--                                    >-->
<!--                                        <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>-->
<!--                                        <circle cx='12' cy='7' r='4'></circle>-->
<!--                                    </svg>-->
<!--                                    Minha Conta-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li role='presentation'>
                                <a href='javascript:;' @click.prevent='logout' class='dropdown-item'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        stroke='currentColor'
                                        stroke-width='2'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        class='feather feather-log-out'
                                    >
                                        <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
                                        <polyline points='16 17 21 12 16 7'></polyline>
                                        <line x1='21' y1='12' x2='9' y2='12'></line>
                                    </svg>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        
        <!--  BEGIN NAVBAR  -->
        <div class='sub-header-container'>
            <header class='header navbar navbar-expand-sm'>
                <a href='javascript:void(0);' class='sidebarCollapse' data-placement='bottom'
                   @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        class='feather feather-menu'
                    >
                        <line x1='3' y1='12' x2='21' y2='12'></line>
                        <line x1='3' y1='6' x2='21' y2='6'></line>
                        <line x1='3' y1='18' x2='21' y2='18'></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id='breadcrumb' class='vue-portal-target'></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';

    const store = useStore();

    const selectedLang = ref(null);
    const countryList = ref(store.state.countryList);

    const i18n = useI18n();

    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };
    
    const logout = () => {
        store.dispatch('user/logout');
    }

    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };
</script>
