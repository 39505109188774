import { createAcl, defineAclRules } from 'vue-simple-acl';
import router from './router';

const user = JSON.parse(localStorage.getItem('_user'));

const rules = () => defineAclRules(async (setRule) => {
    setRule('dashboard-view', (user) => user?.permissions && user?.permissions.includes('dashboard-view'));

    setRule('position-description-view', (user) => user?.permissions && user?.permissions.includes('position-description-view'));
    setRule('position-description-edit', (user) => user?.permissions && user?.permissions.includes('position-description-edit'));
    setRule('position-description-print', (user) => user?.permissions && user?.permissions.includes('position-description-print'));

    setRule('classification-view', (user) => user?.permissions && user?.permissions.includes('classification-view'));
    setRule('classification-to-rank', (user) => user?.permissions && user?.permissions.includes('classification-to-rank'));
    setRule('classification-criteria', (user) => user?.permissions && user?.permissions.includes('classification-criteria'));

    setRule('salary-survey-view', (user) => user?.permissions && user?.permissions.includes('salary-survey-view'));
    setRule('salary-survey-search', (user) => user?.permissions && user?.permissions.includes('salary-survey-search'));
    setRule('salary-survey-add-search', (user) => user?.permissions && user?.permissions.includes('salary-survey-add-search'));
    setRule('salary-survey-remove-companies', (user) => user?.permissions && user?.permissions.includes('salary-survey-remove-companies'));
    setRule('salary-curve-view', (user) => user?.permissions && user?.permissions.includes('salary-curve-view'));
    setRule('salary-curve-save', (user) => user?.permissions && user?.permissions.includes('salary-curve-save'));
    setRule('salary-table-view', (user) => user?.permissions && user?.permissions.includes('salary-table-view'));

    setRule('suitability-table-view', (user) => user?.permissions && user?.permissions.includes('suitability-table-view'));
    setRule('suitability-table-save', (user) => user?.permissions && user?.permissions.includes('suitability-table-save'));
    setRule('suitability-table-percent', (user) => user?.permissions && user?.permissions.includes('suitability-table-percent'));
    setRule('suitability-table-consolidated', (user) => user?.permissions && user?.permissions.includes('suitability-table-consolidated'));
    setRule('suitability-table-history', (user) => user?.permissions && user?.permissions.includes('suitability-table-history'));
    setRule('suitability-table-rollback', (user) => user?.permissions && user?.permissions.includes('suitability-table-rollback'));

    setRule('chart-view', (user) => user?.permissions && user?.permissions.includes('chart-view'));
    setRule('chart-download', (user) => user?.permissions && user?.permissions.includes('chart-download'));
    setRule('chart-order', (user) => user?.permissions && user?.permissions.includes('chart-order'));

    setRule('salary-policy-view', (user) => user?.permissions && user?.permissions.includes('salary-policy-view'));
    setRule('salary-policy-print', (user) => user?.permissions && user?.permissions.includes('salary-policy-print'));
    setRule('salary-policy-update', (user) => user?.permissions && user?.permissions.includes('salary-policy-update'));

    setRule('sector-view', (user) => user?.permissions && user?.permissions.includes('salary-policy-print'));
    setRule('sector-create', (user) => user?.permissions && user?.permissions.includes('sector-create'));
    setRule('sector-edit', (user) => user?.permissions && user?.permissions.includes('sector-edit'));
    setRule('sector-delete', (user) => user?.permissions && user?.permissions.includes('sector-delete'));

    setRule('position-view', (user) => user?.permissions && user?.permissions.includes('position-view'));
    setRule('position-create', (user) => user?.permissions && user?.permissions.includes('position-create'));
    setRule('position-edit', (user) => user?.permissions && user?.permissions.includes('position-edit'));
    setRule('position-delete', (user) => user?.permissions && user?.permissions.includes('position-delete'));

    setRule('user-view', (user) => user?.permissions && user?.permissions.includes('user-view'));
    setRule('user-create', (user) => user?.permissions && user?.permissions.includes('user-create'));
    setRule('user-update', (user) => user?.permissions && user?.permissions.includes('user-update'));
    setRule('user-delete', (user) => user?.permissions && user?.permissions.includes('user-delete'));

    setRule('collaborators-view', (user) => user?.permissions && user?.permissions.includes('collaborators-view'));
    setRule('collaborators-create', (user) => user?.permissions && user?.permissions.includes('collaborators-create'));
    setRule('collaborators-edit', (user) => user?.permissions && user?.permissions.includes('collaborators-edit'));
    setRule('collaborators-delete', (user) => user?.permissions && user?.permissions.includes('collaborators-delete'));
});

const simpleAcl = createAcl({ user, rules, router });
export default simpleAcl;
