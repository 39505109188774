import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import FloatingVue, { Tooltip, VTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css'

import 'vue-multiselect/dist/vue-multiselect.css';
import 'element-plus/dist/index.css'

const app = createApp(App);

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faHouse,
    faBuilding,
    faPenToSquare,
    faTrashCan,
    faMagnifyingGlass,
    faPlus,
    faLocationDot,
    faFloppyDisk,
    faXmark,
    faClipboardCheck,
    faFolderPlus,
    faClipboardList,
    faIdCard,
    faListCheck,
    faChartSimple,
    faMagnifyingGlassPlus,
    faArrowDownShortWide,
    faArrowDownWideShort,
    faBriefcase,
    faPrint,
    faIdCardClip,
    faChartLine,
    faSquareRootVariable,
    faClipboardUser,
    faFileInvoiceDollar,
    faTableList,
    faCaretDown,
    faCodeBranch,
    faCheckToSlot,
    faFilter,
    faReceipt,
    faCashRegister,
    faPercent,
    faPencil,
    faClockRotateLeft,
    faCaretUp,
    faSort,
    faCheckSquare,
    faCheck,
    faDiagramProject,
    faDownload,
    faEye,
    faChevronDown,
    faAngleDown,
    faAddressBook,
    faFileSignature,
    faFileLines,
    faUsers,
    faGear,
    faUnlock,
    faChartPie,
    faEnvelope,
    faUserTag,
    faUserGear,
    faUserTie,
    faUpload,
    faFileContract,
    faCubes,
    faUserPlus,
    faCalculator, faHandshakeSimple, faCalendar, faPhone, faUser
} from '@fortawesome/free-solid-svg-icons';

import {faBell} from '@fortawesome/free-regular-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';

library.add(
    faBuilding,
    faHouse,
    faPenToSquare,
    faTrashCan,
    faMagnifyingGlass,
    faArrowDownShortWide,
    faPlus,
    faLocationDot,
    faFloppyDisk,
    faXmark,
    faClipboardCheck,
    faFolderPlus,
    faClipboardList,
    faIdCard,
    faListCheck,
    faBell,
    faChartSimple,
    faMagnifyingGlassPlus,
    faArrowDownWideShort,
    faBriefcase,
    faPrint,
    faIdCardClip,
    faChartLine,
    faSquareRootVariable,
    faClipboardUser,
    faFileInvoiceDollar,
    faTableList,
    faCaretDown,
    faCodeBranch,
    faFilter,
    faReceipt,
    faCashRegister,
    faPercent,
    faPencil,
    faClockRotateLeft,
    faCaretUp,
    faSort,
    faCheckSquare,
    faCheck,
    faDiagramProject,
    faXmark,
    faDownload,
    faEye,
    faAngleDown,
    faAddressBook,
    faFileSignature,
    faFileLines,
    faUsers,
    faGear,
    faUnlock,
    faChartPie,
    faEnvelope,
    faUserTag,
    faUserGear,
    faUserTie,
    faUpload,
    faFileContract,
    faCubes,
    faUserPlus,
    faCalculator,
    faHandshakeSimple,
    faCalendar,
    faPhone,
    faUser,
);

// bootstrap
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';

const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';

window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';

registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

// set default settings
import appSetting from './app-setting';

import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_HOST_API;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';

axios.interceptors.request.use(function(config) {
    const storageToken = localStorage.getItem('_token');
    if (storageToken) {
        config.headers.Authorization = `Bearer ${storageToken}`;
    }

    return config;
});

app.config.globalProperties.$axios = axios;
window.axios = axios;

window.$appSetting = appSetting;
window.$appSetting.init();

import money from 'v-money3'
import acl from './acl'



app.use(store)
    .use(Toast)
    .use(router)
    .use(acl)
    .use(i18n)
    .use(money)
    .use(PerfectScrollbar)
    .use(VueNouislider)
    .use(Maska)
    .use(ClientTable)
    .use(head)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(FloatingVue)
    .mount('#app');
