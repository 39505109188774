import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
    {
        path: '/',
        redirect: '/contratos'
        // name: 'home',
        // component: () => import('../views/home/home.vue')
    },

    /** PROPOSTAS **/
    {
        path: '/propostas',
        name: 'proposal',
        component: () => import('../views/proposals/proposals.vue')
    },
    {
        path: '/propostas/cadastrar',
        name: 'proposal.create',
        component: () => import('../views/proposals/proposal-form.vue')
    },
    {
        path: '/propostas/editar/:id',
        name: 'proposal.update',
        component: () => import('../views/proposals/proposal-form.vue')
    },

    /** CONTRATOS **/
    {
        path: '/contratos',
        name: 'contract',
        component: () => import('../views/contracts/contracts.vue')
    },
    {
        path: '/contratos/cadastrar',
        name: 'contract.create',
        component: () => import('../views/contracts/contract-form.vue')
    },
    {
        path: '/contratos/editar/:id',
        name: 'contract.update',
        component: () => import('../views/contracts/contract-form.vue')
    },
    {
        path: '/contratos/vizualizar/:id',
        name: 'contract.view',
        component: () => import('../views/contracts/contract-view.vue')
    },
    {
        path: '/contratos/:id/empresas',
        name: 'contract.companies',
        component: () => import('../views/contracts/companies.vue')
    },
    {
        path: '/contratos/:id/empresas/cadastrar',
        name: 'company.create',
        component: () => import('../views/contracts/company-form.vue')
    },
    {
        path: '/contratos/:id/empresas/:company_id',
        name: 'company.edit',
        component: () => import('../views/contracts/company-form.vue')
    },

    /** PARCEIROS **/
    {
        path: '/parceiros',
        name: 'partner',
        component: () => import('../views/partners/partners.vue')
    },
    {
        path: '/parceiros/cadastrar',
        name: 'partner.create',
        component: () => import('../views/partners/partner-form.vue')
    },
    {
        path: '/parceiros/editar/:id',
        name: 'partner.update',
        component: () => import('../views/partners/partner-form.vue')
    },
    {
        path: '/parceiros/vizualizar/:id',
        name: 'partner.view',
        component: () => import('../views/partners/partner-view.vue')
    },
    {
        path: '/parceiros/usuarios/:id',
        name: 'partner.users',
        component: () => import('../views/partners/partners-users.vue')
    },


    /** CALCULAR **/
    {
        path: '/calcular',
        name: 'calculate',
        component: () => import('../views/prices/prices.vue')
    },


    /** AUTH **/
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/recuperacao-senha/:token',
        name: 'recovery',
        component: () => import('../views/auth/recovery.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/redefinir-senha/:token',
        name: 'reset',
        component: () => import('../views/auth/newPassword.vue'),
        meta: { layout: 'auth' }
    }
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        const storageToken = localStorage.getItem('_token');
        const storageUser = JSON.parse(localStorage.getItem('_user'));

        if (storageToken && storageUser) {
            if (!store.state.user.user) {
                store.commit('user/setUser', storageUser);
            }

            if (!store.state.user.token) {
                store.commit('user/setToken', storageToken);
            }
        } else {
            window.location.href = '/login';
        }

        store.commit('setLayout', 'app');
    }

    next(true);
});

export default router;
